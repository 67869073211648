.MapPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {
    max-width: 1128px;
    width: 100%;
    padding: 64px 0;

    header {
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
          font-weight: 500;
          font-size: 34px;
          line-height: 41px;
        }

        span {
          font-weight: 500;
          font-size: 21px;
          line-height: 25px;
          color: #4d4d4d;
        }
      }

      button {
        height: 42px;
        border-radius: 4px;
        text-align: center;
        font-size: 20px;
        line-height: 24px;
      }

      .backButton {
        width: 182px;
        border: 1px solid #007aff;
        background-color: transparent;
      }

      .exportButton {
        width: 140px;
        font-weight: 500;
        border: 1px solid rgba(0, 0, 0, 0.33);
        background-color: #007aff;
        color: #ffffff;
      }
    }

    .map {
      margin-bottom: 56px;
      display: flex;
      flex-direction: column;
      row-gap: 40px;
    }

    .details {
      display: flex;
      justify-content: space-between;

      .statistics {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 6px;

        .total {
          font-weight: 700;
        }

        span {
          font-size: 17px;
          line-height: 21px;
        }
      }

      .tokens {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .tokenContainer {
          display: flex;
          column-gap: 8px;

          .token {
            width: 20px;
            height: 20px;
            border: 1px solid #ffffff;
            border-radius: 50%;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2),
              inset 1px 1px 3px rgba(0, 0, 0, 0.4);

            &.orange {
              background-color: #ea920e;
            }

            &.blue {
              background-color: #228cb9;
            }

            &.purple {
              background-color: #834e8b;
            }

            &.grey {
              background-color: rgba(217, 217, 217, 0.7);
              border: 1px solid rgba(82, 82, 82, 0.92);
              border-radius: unset;
              box-shadow: unset;
            }
          }

          span {
            font-size: 17px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
