.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  .spinnerContainer {
    position: absolute;
  }
}

.count {
  display: none;
  position: sticky;
  top: 0;
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: yellow;
}

.checkIns {
  width: 100%;
  border-collapse: separate;
  border-left: 1px #eee solid;
  border-right: 1px #eee solid;

  thead {
    width: 100%;
    display: table;
    position: sticky;
    top: 0;
    height: 75px;
    table-layout: fixed;
    background-color: white;

    &::after {
      content: '';
      position: absolute;
      top: -4px;
      width: 100%;
      height: 4px;
      background-color: white;
    }

    th,
    td {
      text-align: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #66657e;
      border-bottom: 1px #eee solid;
    }
  }

  tbody {
    width: 100%;
    height: calc(100vh - 60px - 260px - 138px);
    display: block;
    overflow: hidden;
    overflow-y: scroll;
    table-layout: fixed;


    tr {
      height: 124px;
      width: 100%;
      display: table;
      table-layout: fixed;

      &:first-child {
        td,
        th {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #242347;
          border-top: none;
        }
      }
    }
  }

  td,
  th {
    padding: 18px 12px;
    border-top: 1px #eee solid;
    // border-bottom: 1px #eee solid;
    text-align: center;
    font-size: 14px;
    color: #242347;
  }

  th {
    box-sizing: border-box;
    color: #66657e;
    font-size: 16px;
    text-align: left;
  }

  td {
    padding: 10px 12px;
  }

  a {
    color: #0b0f75;
    display: inline-block;
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    white-space: nowrap;
  }

  .avatar {
    img {
      height: 90px;
      width: 90px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .loading {
    text-align: center;
  }
}

.loadMore {
  width: 120px;
  height: 40px;
  line-height: 40px;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  white-space: nowrap;
  margin-top: 30px;
}
