.AdminLoginPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .uqLogo {
    width: 106px;
    height: 62px;
    position: absolute;
    top: 24px;
    left: 24px;
  }

  form {
    width: 400px;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 32px;
      text-align: center;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #171d29;
    }

    button {
      height: 56px;
      border: none;
      border-radius: 6px;
      background-color: #007aff;
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .verificationForm {
    label {
      input {
        text-align: center;
      }
    }

    .inputsContainer {
      margin-bottom: 32px;
      padding: 0 100px;
      display: flex;
      column-gap: 10px;

      input {
        width: 25%;
        height: 40px;
        text-align: center;
        border: 1px solid #e3e4ea;
        caret-color: transparent;

        &:focus {
          outline: none;
        }

        &.active {
          border: 1px solid rgba(0, 0, 0, 0.8);
        }
      }
    }

    .codeInput {
      position: absolute;
      left: -9999px;
      opacity: 0;
    }

    .reset {
      margin: 0;
      width: 100%;
      text-align: right;
      color: #171d29;

      span {
        color: #007aff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
