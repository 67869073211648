.root {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0 auto;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 5px;
  }
}

.header {
  height: 60px;
}

.pageTitle {
  width: 168px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  text-decoration-line: none;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 2px solid #007aff;
}

.tables {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 40px;

  .enters,
  .exits {
    position: relative;
    flex-grow: 1;

    + .exits {
      margin-left: 23px;
    }

    .title {
      position: sticky;
      z-index: 1;
      top: 0;
      width: 100%;
      height: 50px;
      background-color: white;
      text-align: center;
      font-size: 24px;
      color: #007aff;
      text-transform: uppercase;
    }
  }
}

.counters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  background-color: #ffffff;
  z-index: 1;

  div {
    flex: 1;
    padding-top: 44px;
    padding-left: 24px;
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-top: 1px solid #e9ecef;
    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #242347;
    }
  }
}
