.ConfirmationCheckbox {
  width: 18px;
  height: 18px;
  border: 1px solid rgba(8, 134, 6, 0.8);
  border-radius: 3px;
  cursor: pointer;

  &.selected {
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 0C16.8444 0 18 1.08382 18 2.5V15.5C18 16.8444 16.9178 18 15.5 18H2.5C1.15562 18 0 16.9124 0 15.5V2.5C0 1.15562 1.07525 0 2.5 0H15.5ZM2.5 1C1.70116 1 1 1.64223 1 2.5V15.5C1 16.2988 1.63538 17 2.5 17H15.5C16.2988 17 17 16.3442 17 15.5V2.5C17 1.70116 16.3415 1 15.5 1H2.5ZM13.682 5.81802C13.8622 5.99826 13.8923 6.31308 13.682 6.52513L8.02513 12.182C7.84043 12.3667 7.52411 12.4037 7.30592 12.1694L5.13435 9.84102C4.946 9.63908 4.95703 9.32269 5.15898 9.13435C5.34539 8.96049 5.6605 8.93338 5.86565 9.15898L7.684 11.1085L12.9749 5.81802C13.1701 5.62276 13.4867 5.62276 13.682 5.81802Z' fill='%23088606' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
  }
}
