.Header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 61px;
  padding-left: 28px;
  position: relative;
  border-bottom: 2px solid #e9ecef;

  .logo {
    // width: 117px;
    max-height: 80%;
    height: 100%;
    object-fit: contain;
  }

  .children {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .rightBlock {
    height: 100%;
    margin-left: auto;
    display: flex;
    border-left: 2px solid #e9ecef;

    button {
      height: 100%;
      padding: 0 24px;
      border: none;
      background-color: transparent;
    }

    .languagePicker {
      position: relative;
      border-right: 2px solid #e9ecef;

      ul {
        margin: 0;
        padding: 0;
        position: absolute;
        left: -2px;
        right: -2px;
        z-index: 2;
        border: 2px solid #e9ecef;
        border-top: none;
        list-style: none;

        li {
          padding: 8px 0;
          text-align: center;
          border-top: 2px solid #e9ecef;
          text-transform: uppercase;
          background-color: #ffffff;
          cursor: pointer;
        }
      }
    }

    .menuButton {
      position: relative;

      &::after {
        content: 'menu';
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
      }
    }
  }
}
