.Calendar {
  width: 100%;
  max-width: 204px;
  .input {
    height: 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 8px;
    color: #9191a3;
    line-height: 24px;
    letter-spacing: 0.1px;
    background-color: transparent;
    border: 1px solid rgba(145, 145, 163, 0.3);
    border-radius: 4px;
    cursor: pointer;

    &.hasValue {
      color: unset;
    }
  }

  .modal {
    position: fixed;
    z-index: 1111;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(64, 64, 64, 0.2);

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      padding-bottom: 35px;
      transform: translateX(-50%) translateY(-50%);
      background-color: white;
      border-radius: 10px 10px 0px 0px;

      .row {
        display: flex;
        gap: 61px;

        &:last-child {
          gap: 124px;
          padding-right: 41px;
        }

        .cancelButton {
          margin-left: auto;
          padding: 0;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #333333;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .applyButton {
          width: 100px;
          height: 40px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: white;
          background-color: #007aff;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }

        .calenderContainer {
          min-height: 280px;
          margin-bottom: 36px;
          padding-top: 39px;
          padding-right: 49px;
        }

        .checkboxes {
          padding-top: 68px;
          padding-left: 43px;
          ul {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              display: flex;
              align-items: center;
              gap: 11px;
              cursor: pointer;

              &.selected {
                span {
                  color: #333333;
                }

                .checkbox {
                  border: none;
                  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 0C16.8444 0 18 1.08382 18 2.5V15.5C18 16.8444 16.9178 18 15.5 18H2.5C1.15562 18 0 16.9124 0 15.5V2.5C0 1.15562 1.07525 0 2.5 0H15.5ZM2.5 1C1.70116 1 1 1.64223 1 2.5V15.5C1 16.2988 1.63538 17 2.5 17H15.5C16.2988 17 17 16.3442 17 15.5V2.5C17 1.70116 16.3415 1 15.5 1H2.5ZM13.682 5.81802C13.8622 5.99826 13.8923 6.31308 13.682 6.52513L8.02513 12.182C7.84043 12.3667 7.52411 12.4037 7.30592 12.1694L5.13435 9.84102C4.946 9.63908 4.95703 9.32269 5.15898 9.13435C5.34539 8.96049 5.6605 8.93338 5.86565 9.15898L7.684 11.1085L12.9749 5.81802C13.1701 5.62276 13.4867 5.62276 13.682 5.81802Z' fill='black' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
                }
              }

              span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: rgba(0, 0, 0, 0.6);
              }

              .checkbox {
                width: 18px;
                height: 18px;
                border: 1px solid rgba(0, 0, 0, 0.5);
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}
