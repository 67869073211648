.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linkInput {
  box-sizing: border-box;
  width: 555px;
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  border: 1px solid #007AFF;
  border-radius: 4px;
}

.userPageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 30px;

  + .userPageContainer {
    border-top: 2px solid rgba(34, 112, 197, 0.5);
  }

  .preview {
    width: 300px;
    height: 600px;
    border: none;
    outline: none;
  }

  .controls {
    display: flex;
    flex-direction: column;
    padding-left: 30px;

    button {
      width: 200px;
      line-height: 48px;
      border-radius: 4px;
      font-size: 16px;
      letter-spacing: 2.5px;
      text-align: center;
      box-shadow: none;
      background: #007AFF;
      white-space: nowrap;
      text-transform: uppercase;
      cursor: pointer;

      + button {
        margin-top: 16px;
      }
    }

    .confirmEnter {
      background: #007AFF;
      border: 1px solid rgba(145, 145, 163, 0.3);
      color: #ffffff;

      &:hover {
        background: lighten(#007AFF, 10%);
      }
    }

    .confirmExit {
      background: #FAC21A;
      border: 1px solid rgba(145, 145, 163, 0.3);
      color: #ffffff;

      &:hover {
        background: lighten(#FAC21A, 10%);
      }
    }

    .remove {
      background: transparent;
      line-height: 46px;
      border: 2px solid #EC2B2B;
      color: #EC2B2B;

      &:hover {
        background: #EC2B2B;
        color: #ffffff;
      }
    }
  }
}
