.TextInput {
  width: 100%;
  max-width: 204px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 8px;
  line-height: 24px;
  letter-spacing: 0.1px;
  background-color: transparent;
  border: 1px solid rgba(145, 145, 163, 0.3);
  border-radius: 4px;

  &:focus {
    outline: 1px solid burlywood;
  }

  &::placeholder {
    color: #9191a3;
  }
}
