.SecurityLoginPage {
  height: 100vh;
  display: flex;

  .leftSide, .rightSide {
    width: 50%;
    height: 100%;
  }

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      width: 400px;
      display: flex;
      flex-direction: column;

      h1 {
        margin-bottom: 32px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #171d29;
      }
    }
  }

  .rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    img {
      width: 264px;
      height: 154px;
    }
  }
}
