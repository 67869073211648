.Drawer {
  .backdrop {
    opacity: 0;
    background-color: #000000;
    transition: opacity 0.4s;

    &.show {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      opacity: 0.2;
    }
  }

  .content {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -400px;
    border-right: 2px solid #e9ecef;
    border-left: 2px solid #e9ecef;
    background-color: #ffffff;
    z-index: 3;
    transition: left 0.2s;

    &.show {
      left: 0;
    }

    nav {
      display: flex;
      flex-direction: column;

      a {
        padding-left: 44px;
        border-bottom: 2px solid #e9ecef;
        font-size: 20px;
        line-height: 80px;
        color: rgba(0, 0, 0, 0.8);
        text-decoration: none;

        &.active {
         color: #007aff;
        }
      }
    }

    .logoutButton {
      height: 60px;
      border: none;
      border-top: 2px solid #e9ecef;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.8);
      background-color: transparent;
      background-image: url("../../assets/images/logout.svg");
      background-repeat: no-repeat;
      background-position: calc(50% + 60px) center;
    }
  }
}
