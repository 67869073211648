.AdminPanelPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .navigation {
    display: flex;
    column-gap: 16px;

    .link {
      flex: 1 1 0;
      width: 168px;
      height: 100%;
      text-align: center;
      line-height: 60px;
      font-size: 25px;
      text-decoration-line: none;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;

      &.active {
        border-bottom: 2px solid #007aff;
      }
    }
  }

  table {
    width: 100%;
    padding: 60px;
    border-collapse: separate;

    thead {
      position: sticky;
      top: 3px;
      z-index: 1;
      background-color: white;

      &::after {
        content: '';
        position: absolute;
        top: -3px;
        width: 100%;
        height: 3px;
        background-color: white;
      }

      tr {
        th {
          padding: 12px 0;
          text-align: center;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #66657e;
          border-bottom: 1px solid #e9e9ed;

          &:first-child {
            width: 10%;
            padding-left: 24px;
          }

          &:last-child {
            width: 10%;
            text-align: end;
            padding-right: 38px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 14px 0;
          text-align: center;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #242347;
          border-bottom: 1px solid #e9e9ed;

          &:first-child {
            width: 10%;
            padding-left: 24px;
            padding-right: 0;
            white-space: nowrap;
          }

          &:last-child {
            width: 10%;
            padding-right: 38px;
          }
        }

        .buttons {
          text-align: end;

          button {
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }

  .spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .applyButton {
    align-self: flex-end;
    width: 104px;
    height: 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: white;
    background-color: #007aff;
    border: 1px solid rgba(145, 145, 163, 0.3);
    border-radius: 4px;
  }
}
