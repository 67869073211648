.Input {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 4px;
    font-family: 'Manrope';
    font-size: 12px;
    line-height: 16px;
    color: #171d29;
  }

  input {
    height: 48px;
    padding: 14px 16px 16px;
    margin-bottom: 16px;
    border: 1px solid #e3e4ea;
    border-radius: 6px;
    font-family: 'Manrope';
    font-size: 14px;
    line-height: 17px;
    color: #81858b;

    &.active {
      color: #333333;
    }

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.8);
      outline: none;
      color: #333333;
    }
  }

  .passwordContainer {
    margin-bottom: 16px;
    position: relative;

    input {
      width: 100%;
      margin: 0;
    }

    .eyeIcon {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-60%);
      cursor: pointer;

      svg {
        path {
          transition: all 0.3s;
        }

        &:hover path {
          stroke: #333333;
          fill: #333333;
        }
      }
    }
  }

  .error {
    margin: -10px 0 10px;
    bottom: 0;
    font-family: 'Manrope';
    color: #d8000c;
  }
}
