.Select {
  position: relative;
  width: 100%;
  // max-width: 108px;

  .selector {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #9191a3;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_36_974' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='-1' width='8' height='6'%3E%3Cpath d='M0.296477 1.70976L2.88648 4.29976C3.27648 4.68976 3.90648 4.68976 4.29648 4.29976L6.88648 1.70976C7.51648 1.07976 7.06648 -0.000244141 6.17648 -0.000244141H0.996477C0.106477 -0.000244141 -0.333523 1.07976 0.296477 1.70976Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_36_974)'%3E%3Cpath d='M0.296477 1.70976L2.88648 4.29976C3.27648 4.68976 3.90648 4.68976 4.29648 4.29976L6.88648 1.70976C7.51648 1.07976 7.06648 -0.000244141 6.17648 -0.000244141H0.996477C0.106477 -0.000244141 -0.333523 1.07976 0.296477 1.70976Z' fill='%23828282'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 17px;
    border: 1px solid rgba(145, 145, 163, 0.3);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
  }

  .listContainer {
    position: absolute;
    z-index: 1000;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 11px;
    padding-right: 11px;
    background-color: white;
    border: 1px solid #ededee;

    ul {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        gap: 19px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(23, 29, 41, 0.5);
        cursor: pointer;

        &.selected {
          color: rgba(0, 0, 0, 0.8);

          .checkbox {
            border: none;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 0C16.8444 0 18 1.08382 18 2.5V15.5C18 16.8444 16.9178 18 15.5 18H2.5C1.15562 18 0 16.9124 0 15.5V2.5C0 1.15562 1.07525 0 2.5 0H15.5ZM2.5 1C1.70116 1 1 1.64223 1 2.5V15.5C1 16.2988 1.63538 17 2.5 17H15.5C16.2988 17 17 16.3442 17 15.5V2.5C17 1.70116 16.3415 1 15.5 1H2.5ZM13.682 5.81802C13.8622 5.99826 13.8923 6.31308 13.682 6.52513L8.02513 12.182C7.84043 12.3667 7.52411 12.4037 7.30592 12.1694L5.13435 9.84102C4.946 9.63908 4.95703 9.32269 5.15898 9.13435C5.34539 8.96049 5.6605 8.93338 5.86565 9.15898L7.684 11.1085L12.9749 5.81802C13.1701 5.62276 13.4867 5.62276 13.682 5.81802Z' fill='black' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
          }
        }

        .checkbox {
          width: 18px;
          height: 18px;
          border: 1px solid rgba(0, 0, 0, 0.8);
          border-radius: 3px;
        }
      }
    }
  }
}
