.AbsencePage {
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 5px;
  }

  .header {
    height: 60px;
  }

  .pageTitle {
    width: 168px;
    text-align: center;
    line-height: 60px;
    font-size: 25px;
    text-decoration-line: none;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 2px solid #007aff;
  }

  .tableContainer {
    height: calc(100vh - 60px - 138px);
    width: 100%;
    position: relative;
    border-collapse: separate;
    border-left: 1px #eee solid;
    border-right: 1px #eee solid;

    .spinnerContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    thead {
      width: 100%;
      display: table;
      position: sticky;
      top: 0;
      height: 75px;
      table-layout: fixed;
      background-color: white;
  
      &::after {
        content: '';
        position: absolute;
        top: -4px;
        width: 100%;
        height: 4px;
      }
  
      th,
      td {
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #66657e;
        border-bottom: 1px #eee solid;
      }

      th {
        .sortButton {
          background-color: transparent;
          border: none;
        }
      }
    }
  
    tbody {
      width: 100%;
      display: block;
      overflow: hidden;
      overflow-y: scroll;
      table-layout: fixed;

      tr {
        height: 124px;
        width: 100%;
        display: table;
        table-layout: fixed;
  
        &:first-child {
          td,
          th {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #242347;
            border-top: none;
          }
        }
      }
    }
  
    td,
    th {
      padding: 18px 12px;
      border-top: 1px #eee solid;
      border-bottom: 1px #eee solid;
      text-align: center;
      font-size: 14px;
      color: #242347;
    }
  
    th {
      box-sizing: border-box;
      color: #66657e;
      font-size: 16px;
      text-align: left;
    }
  
    td {
      padding: 10px 12px;
    }

    .absence {
      background-color: #eeeeee;
    }
  
    .avatar {
      img {
        height: 180px;
        width: 180px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
