.Filters {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 138px;
  padding-top: 28px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: rgba(0, 122, 255, 0.02);

  &.singleLine {
    .filtersRow {
      .filtersContainer {
        flex: unset;
        justify-content: flex-start;

        & > div {
          width: 160px;

          &:first-child {
            width: 204px;
          }
        }
      }

      .buttons {
        .resetButton {
          margin-left: 7px;
        }
      }
    }

    .exportRow {
      .exportButton {
        position: absolute;
        top: 29px;
        right: 24px;
      }
    }
  }

  .filtersRow {
    display: flex;
    gap: 17px;
    align-items: center;
    margin-bottom: 16px;

    .buttons {
      display: flex;
      gap: 17px;

      button {
        cursor: pointer;

        &.resetButton {
          padding: 0;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #007aff;
          background-color: transparent;
          border: none;
        }

        &.applyButton {
          width: 104px;
          height: 40px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: white;
          background-color: #007aff;
          border: 1px solid rgba(145, 145, 163, 0.3);
          border-radius: 4px;
        }
      }
    }

    .filtersContainer {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      flex: 1;
    }
  }

  .exportRow {
    width: 100%;
    .exportButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 136px;
      height: 40px;
      margin-left: auto;
      background-color: white;
      border: 1px solid #007aff;
      border-radius: 8px;
      cursor: pointer;

      &:disabled {
        color: #dddddd;;
        border-color: #dddddd;
        cursor: not-allowed;
      }
    }
  }
}
