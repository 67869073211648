.ManagerLoginPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .uqLogo {
    width: 106px;
    height: 62px;
    position: absolute;
    top: 24px;
    left: 24px;
  }

  form {
    width: 400px;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 32px;
      text-align: center;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #171d29;
    }

    button {
      height: 56px;
      border: none;
      border-radius: 6px;
      background-color: #007aff;
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
