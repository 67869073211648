.TimeRange {
  display: flex;

  .input {
    a {
      top: 7px;
      right: 3px;
    }
    input {
      width: 80px;
      height: 40px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      background-color: transparent;
      border: 1px solid rgba(145, 145, 163, 0.3);
      border-radius: 4px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #9191a3;
      }
    }

    &.from {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    &.to {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
