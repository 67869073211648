.Toast {
  width: 350px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 48px;
  right: 48px;
  border-radius: 4px;
  opacity: 0;
  font-family: 'Manrope';
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  background-color: #007aff;
  transition: opacity 0.3s;

  &.active {
    opacity: 1;
  }
}
