.DeleteConfirmationModal {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 24px 24px;

  h1 {
    margin: 0;
    margin-bottom: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;

    button {
      width: 104px;
      height: 40px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.1px;
      color: white;
      border: 1px solid rgba(145, 145, 163, 0.3);
      border-radius: 4px;
    }

    .cancelButton {
      background-color: transparent;
      color: #000000;
    }

    .deleteButton {
      background-color: #ff0000;
    }
  }
}
