.Modal {
  position: fixed;
  z-index: 1111;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(64, 64, 64, 0.2);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: white;
    border-radius: 8px;

    .row {
      display: flex;

      .calenderContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px 60px;

        .calendar {
          min-height: 260px;
        }
      }

      .buttonsContainer {
        display: flex;
        justify-content: space-between;

        .cancelButton {
          width: 100px;
          height: 40px;
          padding: 0;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #333333;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .applyButton {
          width: 100px;
          height: 40px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: white;
          background-color: #007aff;
          border-radius: 5px;
          border: none;
          cursor: pointer;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }

      .ranges {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 0;
        border-right: 2px solid #e9ecef;

        ul {
          width: 180px;
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            width: 100%;
            padding: 12px 20px;
            text-align: center;
            user-select: none;
            cursor: pointer;

            &.selected {
              background-color: #007aff;

              span {
                color: #ffffff;
              }
            }

            span {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 21px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }
  }
}
