.FilledButton {
  height: 56px;
  border: none;
  border-radius: 6px;
  background-color: #007aff;
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}