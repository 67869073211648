.calendarComponent {
  width: 307px;
  border: none;

  .react-calendar__month-view__days {
    button {
      &:hover {
        background-color: #e9ecef;
      }
    }
  }

  .react-calendar__tile--now {
    background-color: #e9ecef;
    border-radius: 4px;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .react-calendar__tile--hover {
    background-color: #e9ecef;
    color: #000000;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    height: unset;
    margin-bottom: 36px;
    padding-left: 10px;
    padding-right: 10px;

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__next-button,
    .react-calendar__navigation__prev-button {
      position: relative;
      top: -2px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: unset;
      height: 0;
      padding: 0;
      font-size: 26px;
      color: #007aff;
    }

    .react-calendar__navigation__label {
      pointer-events: none;

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #333333;
      }
    }
  }

  .react-calendar__month-view {
    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        height: unset;
        margin-bottom: 2px;
        padding: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #007aff;

        abbr {
          text-decoration: none;
        }
      }
    }

    .react-calendar__month-view__days {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        padding: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #333333;

        &.react-calendar__tile--active,
        &.react-calendar__tile--hasActive {
          color: white;
          background-color: #007aff;
          border-radius: unset;
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #e9e9e9;

          &.react-calendar__tile--hover {
            color: #333333;
          }
        }

        &.react-calendar__tile--rangeStart {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &.react-calendar__tile--rangeEnd {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
