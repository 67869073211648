.NotFoundPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  .st1 {
    font-size: 83.0285px;
  }
  .st2 {
    fill: black;
  }

  svg {
    height: 400px;
    width: 75%;
    text-align: center;
    fill: #000000;
  }

  #XMLID_5_ {
    fill: #c30909;
    filter: url(#blurFilter4);
  }

  #XMLID_11_,
  #XMLID_2_ {
    fill: red;
  }

  .circle {
    animation: out 2s infinite ease-out;
    fill: red;
  }

  .message {
    color: #000;
  }

  .message a {
    text-decoration: none;
    color: black;
    font-size: large;
  }

  .message:after {
    content: ']';
  }

  .message:before {
    content: '[';
  }

  .message:after,
  .message:before {
    color: red;
    font-size: 22px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: opacity;
    animation-name: opacity;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    margin: 0 25px;
  }

  @-webkit-keyframes opacity {
    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }

  @keyframes opacity {
    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }

  @keyframes out {
    0% {
      r: 1;
      opacity: 0.9;
    }

    100% {
      r: 20;
      opacity: 0;
    }
  }
}
