.CreateOrUpdateUserModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 48px 24px 24px;

  .closeIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    border: none;
    background-color: transparent;
  }

  form {
    display: flex;
    flex-direction: column;

    h1 {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #171d29;
    }

    .login {
      margin-bottom: 32px;
    }

    p {
      text-align: right;

      span {
        text-decoration: underline;
        color: #007aff;
        cursor: pointer;
      }
    }
  }
}
