.DeclinesPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 5px;
  }

  .header {
    height: 60px;
  }

  .pageTitle {
    width: 168px;
    text-align: center;
    line-height: 60px;
    font-size: 25px;
    text-decoration-line: none;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 2px solid #007aff;
  }

  .tableContainer {
    position: relative;
    height: calc(100vh - 60px - 138px - 69px);
    border-top: 1px solid #e9e9ed;
    overflow-y: auto;

    .spinnerContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    table {
      width: 100%;
      border-collapse: separate;
      thead {
        position: sticky;
        top: 3px;
        z-index: 1;
        background-color: white;

        &::after {
          content: '';
          position: absolute;
          top: -3px;
          width: 100%;
          height: 3px;
          background-color: white;
        }

        tr {
          th {
            padding-top: 22px;
            padding-bottom: 14px;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #66657e;
            border-bottom: 1px solid #e9e9ed;

            &:first-child {
              padding-left: 24px;
            }

            &:last-child {
              padding-right: 38px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding-top: 18px;
            padding-bottom: 18px;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #242347;
            border-bottom: 1px solid #e9e9ed;

            &:first-child {
              padding-left: 24px;
              padding-right: 0;
              white-space: nowrap;
            }

            &:nth-last-child(2), &:last-child {
              div, button {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
              }
            }

            &:last-child {
              width: 80px;
              padding-right: 38px;

              button {
                border: none;
                background-color: transparent;
              }
            }

            a {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.1px;
              color: #0b0f75;
            }
          }

          .note {
            max-width: 400px;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  .counter {
    padding: 22px;
    padding-left: 24px;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #242347;
    }
  }
}
