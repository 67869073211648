.DeclineModal {
  display: flex;
  flex-direction: column;
  padding: 24px;

  h1 {
    margin: 0;
    margin-bottom: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  textarea {
    width: 450;
    height: 123px;
    margin-bottom: 24px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    resize: none;

    &:focus {
      outline: 1px solid burlywood;
    }
  }

  button {
    width: 104px;
    height: 40px;
    margin-left: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: white;
    background-color: #007aff;
    border: 1px solid rgba(145, 145, 163, 0.3);
    border-radius: 4px;
  }

  .error {
    color: red;
  }
}
